import React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import style from './style.module.css';

export default function Nav({ value, setValue }) {

  return (
    <div className={ style.navclass }>
        <Box sx={{ width: "100vw" }}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Convite" icon={<LocalPostOfficeIcon />} />
          <BottomNavigationAction label="Confirmar Presença" icon={<FavoriteIcon />} />
          <BottomNavigationAction label="Local" icon={<LocationOnIcon />} />
        </BottomNavigation>
        </Box>
    </div>
    
  )
}
