import React, { useState } from 'react';
import FormfacadeEmbed from "@formfacade/embed-react";
import Nav from '../../components/nav';
import style from './style.module.css';
import convite_bg from '../../images/convite_bg.png';
import { Player } from '@lottiefiles/react-lottie-player';
import spinning from '../../animations/spinning.json';
import bf from '../../animations/bf.json';
import birds from '../../animations/birds.json';
import girasol from '../../animations/girasol.json';

export default function Home() {

  const [value, setValue] = useState(0);

  const convite = 
    <div className={ style.convite }>
      <img src={ convite_bg } alt="convite_bg" />
      <Player
        autoplay
        className={ style.flor }
        loop
        src={ spinning }
        style={{ height: '150px', width: '150px' }}
      >
      </Player>
      <Player
        autoplay
        className={ style.bf }
        loop
        src={ bf }
        style={{ height: '200px', width: '200px' }}
      >
      </Player>
      <Player
        autoplay
        className={ style.birds }
        loop
        src={ birds }
        style={{ height: '200px', width: '200px' }}
      >
      </Player>
      <Player
        autoplay
        className={ style.girasol }
        loop
        src={ girasol }
        style={{ height: '200px', width: '200px' }}
      >
      </Player>                                                            
    </div>

  const confirmacao = 
    <div className={ style.convite2 }>
      <div className={ style.formc }>
        <FormfacadeEmbed
        formFacadeURL="https://formfacade.com/include/104950838217040910274/form/1FAIpQLSf0tA89P9Bg56xKkIvjXq2RPOKRe1xbEp1f85cL0gJReXXIBQ/classic.js/?div=ff-compose"
        onSubmitForm={() => console.log('Form submitted')}
        />
      </div>
    </div>

  const local = 
    <div className={ style.convite }>
      <div className={ style.end }>
        <h1>
          Rua Maximiliano Martins Magalhães, 3362, são Lucas
        </h1>
      </div>
    </div>

  return (
    <>
        { value === 0 ? convite : null }
        { value === 1 ? confirmacao : null }
        { value === 2 ? local : null }
      <Nav value={ value } setValue={ setValue } />
    </>
  )
}
