import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loading from './components/loading';
import Home from './pages/Home/index.js';

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route path="/" element={<Loading />} />
        <Route exact path='/convite' element={ <Home /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
