import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';
import daisies from "../../animations/lottie_flower.json";
import style from './style.module.css';

export default function MisteriousLetter({ content }) {

  const [loaded, setLoading] = useState(false);

  const navigate = useNavigate();

  const changeLoading = (event) => {
    if (event === 'complete') {
      setLoading(true);
    }
  }

  const animation = 
    <Player
      onEvent={ changeLoading }
      autoplay
      loop={ false }
      src={ daisies }
      style={{ height: '300px', width: '300px' }}
    >
    </Player>

  return (
    <main className={ style.login_main }>
      { loaded ? navigate('/convite')  : animation }
    </main>
  )
}
